import React, { useEffect, useRef } from 'react';
import { AlsSubmitButton } from '@alcs/core/components/AlsSubmitButton';
import { createDefaultI18n, useI18n } from '@alcs/i18n';
import { Find_user_companies } from '@alcs/services';
import { Icon, InputAdornment, styled, TextField } from '@mui/material';
import { ReactiveFormProvider, useForm } from '@reactive-forms/core';
import Cookies from 'js-cookie';
import isNil from 'lodash/isNil';
import { useRouter } from 'next/dist/client/router';

import { SelectField } from '@alcs/core/components/fields/SelectField';

import { PageContainer } from '../components/PageContainer';
import { useLoginResult } from '../SessionStorage/useLoginResult';
import { isServer } from '../utils/isServer';
import { useRedirectToMain } from '../utils/useRedirectToMain';

const defaultI18n = createDefaultI18n('components.choose-company', {
    backToSignIn: 'Back to sign in',
    chooseCompany: 'Choose company',
    signIn: 'Sign In',
});

type ChooseCompanyFormValues = {
    company: Find_user_companies | undefined;
};

export const companyIdCookieKey = 'comId';

const Wrapper = styled(PageContainer, {
    label: 'ChooseCompanyWrapper',
})({
    maxWidth: 345,
    width: 'min(345px, calc(100vw - 120px))',
});

const FieldWrapper = styled('div', {
    label: 'ChooseCompanyFieldWrapper',
})({
    display: 'flex',
    flexDirection: 'column',
});

const ChooseCompany = () => {
    const i18n = useI18n(defaultI18n);
    const router = useRouter();
    const { loginResult, isLoginResultLoaded } = useLoginResult();
    const redirectToMain = useRedirectToMain();

    const formBag = useForm<ChooseCompanyFormValues>({
        initialValues: {
            company: undefined,
        },
    });

    const { paths, setFieldValue } = formBag;

    const isFirstTime = useRef(true);

    useEffect(() => {
        if (!isServer() && isLoginResultLoaded && isNil(loginResult)) {
            router.replace({
                pathname: '/alcs/Login.html',
                hash: window.location.hash,
            });
        }
    }, [isLoginResultLoaded, loginResult, router]);

    useEffect(() => {
        if (isFirstTime.current && isLoginResultLoaded && loginResult) {
            isFirstTime.current = false;

            const savedComId = Cookies.get(companyIdCookieKey);

            if (typeof savedComId === 'string') {
                const comId = parseInt(savedComId);

                const company = loginResult.userCompanies.find(({ com_id }) => com_id === comId);

                setFieldValue(paths.company, company);
            } else {
                setFieldValue(paths.company, loginResult.userCompanies[0]);
            }
        }
    }, [isLoginResultLoaded, loginResult, paths.company, setFieldValue]);

    const chooseCompany = async (values: ChooseCompanyFormValues) => {
        redirectToMain(values.company!.com_id, loginResult!.redirectUrl);
    };

    return (
        <Wrapper title={i18n.chooseCompany} backTitle={i18n.backToSignIn} backHref="/alcs/Login.html">
            <FieldWrapper>
                {loginResult && (
                    <ReactiveFormProvider formBag={formBag}>
                        <TextField
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon color="action">person_rounded_icon</Icon>
                                    </InputAdornment>
                                ),
                            }}
                            disabled={true}
                            value={[loginResult.userFirstname, loginResult.userSurname].join(' ')}
                        />
                        <SelectField
                            name={paths.company}
                            renderItem={item => item?.com_name ?? ''}
                            items={loginResult.userCompanies}
                            textFieldProps={{
                                variant: 'standard',
                                autoFocus: true,
                            }}
                            required
                        />
                        <AlsSubmitButton
                            sx={{ marginTop: '20px' }}
                            variant="contained"
                            color="primary"
                            submitAction={chooseCompany}
                        >
                            {i18n.signIn}
                        </AlsSubmitButton>
                    </ReactiveFormProvider>
                )}
            </FieldWrapper>
        </Wrapper>
    );
};

export default ChooseCompany;
