import React, { useCallback, useMemo, useState } from 'react';
import { ErrorTooltip } from '@alcs/core/components/MaterialTheme';
import { commonsDefaultI18n, useI18n } from '@alcs/i18n';
import { Icon, IconButton, InputAdornment } from '@mui/material';
import { FieldValidator } from '@reactive-forms/core';
import { useUncontrolledField } from '@reactive-forms/dom';
import { Pxth } from 'pxth';
import * as yup from 'yup';

import { StyledTextField } from '@alcs/core/components/fields/Fields';

export type PasswordFieldProps = {
    name: Pxth<string>;
    label: string;
    validator?: FieldValidator<string>;
};

export const PasswordField = ({ name, label, validator }: PasswordFieldProps) => {
    const commonsI18n = useI18n(commonsDefaultI18n);
    const passwordValidationSchema = useMemo(() => yup.string().required(commonsI18n.required), [commonsI18n.required]);

    const [{ ref: inputRef, ...bag }, { error, touched }] = useUncontrolledField({
        name,
        schema: passwordValidationSchema,
        validator,
    });

    const showError = Boolean(error?.$error && touched?.$touched);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = useCallback(() => {
        setIsPasswordVisible(old => !old);
    }, []);

    return (
        <StyledTextField
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon>lock_rounded</Icon>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <ErrorTooltip title={showError ? error!.$error! : ''}>
                            <IconButton onClick={togglePasswordVisibility}>
                                <Icon color={showError ? 'error' : 'inherit'}>
                                    {isPasswordVisible ? 'visibility_off' : 'visibility'}
                                </Icon>
                            </IconButton>
                        </ErrorTooltip>
                    </InputAdornment>
                ),
            }}
            inputProps={{
                style: {
                    paddingLeft: 5,
                },
            }}
            type={isPasswordVisible ? 'text' : 'password'}
            variant="standard"
            error={error?.$error}
            touched={touched?.$touched}
            label={label}
            inputRef={inputRef}
            {...bag}
        />
    );
};
