import { createDefaultI18n, useI18n } from '@alcs/i18n';

const defaultI18n = createDefaultI18n('components.login', {
    username: 'Username (email)',
    password: 'Password',
    staySignedIn: 'Stay signed in',
    signIn: 'Sign In',
    forgotMyPassword: 'Forgot my password',
    newCompany: 'New company',
    newUser: 'New user',
    goToFracht: 'Go to Fracht WEB',
    back: 'Back',
    registrationCompleted: 'You have successfully completed registration!',
    loginWarning: 'Authentication failed. Please sign in again.',
    sessionExpiredError: 'User session expired - please try to login again',
});

export const useLoginI18n = () => useI18n(defaultI18n);
