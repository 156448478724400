import { LoginResult } from '@alcs/services';

import { useSessionStorage } from './useSessionStorage';

const loginResultSessionStorageKey = 'loginResult';

export const useLoginResult = () => {
    const { value, setValue, isLoaded } = useSessionStorage<LoginResult>(loginResultSessionStorageKey);

    return {
        loginResult: value,
        setLoginResult: setValue,
        isLoginResultLoaded: isLoaded,
    };
};
