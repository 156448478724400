import { useCallback } from 'react';
import { Spr_user_message_ot } from '@alcs/beans';
import { useService } from '@alcs/core/utils/useService';
import { emptyServiceCallback, LoginService } from '@alcs/services';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { sessionExpiredErrorTransporter } from './Transporters';
import { useLoginI18n } from './useLoginI18n';
import { useLanguage } from '../components/I18nResourceLoader';
import { companyIdCookieKey } from '../pages/choose-company';
import { useSender } from '../SessionStorage/useTransporter';

export const useRedirectToMain = () => {
    const loginService = useService(LoginService, emptyServiceCallback);
    const router = useRouter();
    const [language] = useLanguage();
    const sendError = useSender(sessionExpiredErrorTransporter);
    const loginI18n = useLoginI18n();

    const redirectToMain = useCallback(
        async (comId: number, redirectUrl: string) => {
            try {
                await loginService.setSessionCompany(comId);
                await loginService.setSessionLanguage(language.value_code);
                Cookies.set(companyIdCookieKey, comId.toString(), { expires: 365 });

                router.push({
                    pathname: redirectUrl,
                    hash: window.location.hash,
                    query: {
                        cid: comId,
                    },
                });
                sessionStorage.clear();
            } catch (error) {
                const message = (error as AxiosError<{ messages?: Spr_user_message_ot[] }>).response?.data
                    .messages?.[0];
                sessionStorage.clear();
                sendError(message?.default_text ?? loginI18n.sessionExpiredError, '/alcs/Login.html');
            }
        },
        [language.value_code, loginI18n.sessionExpiredError, loginService, router, sendError],
    );

    return redirectToMain;
};
