import { useCallback } from 'react';
import { useService } from '@alcs/core/utils/useService';
import { emptyServiceCallback, LoginService } from '@alcs/services';
import { useRouter } from 'next/router';

import { useRedirectToMain } from './useRedirectToMain';
import { useLoginResult } from '../SessionStorage/useLoginResult';

const CHOOSE_COMPANY_PAGE_PATH = '/choose-company';
const CHOOSE_COMPANY_BROWSER_PATH = '/alcs/auth/choose-company';

export const useLogin = () => {
    const loginService = useService(LoginService, emptyServiceCallback);
    const { setLoginResult } = useLoginResult();
    const redirectToMain = useRedirectToMain();
    const router = useRouter();

    const login = useCallback(
        async (...params: Parameters<LoginService['create_session_by_pass']>) => {
            const loginResult = await loginService.create_session_by_pass(...params);

            if (loginResult) {
                setLoginResult(loginResult);

                if (loginResult.userCompanies.length === 1) {
                    const comId = loginResult.userCompanies[0].com_id;
                    redirectToMain(comId, loginResult.redirectUrl);
                } else {
                    router.push(CHOOSE_COMPANY_PAGE_PATH, {
                        pathname: CHOOSE_COMPANY_BROWSER_PATH,
                        hash: window.location.hash,
                    });
                }
            }
        },
        [loginService, redirectToMain, router, setLoginResult],
    );

    return login;
};
