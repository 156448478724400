import { useEffect, useState } from 'react';
import { useRefCallback } from '@alcs/core/utils/useRefCallback';
import { useRouter } from 'next/router';
import { Pxth } from 'pxth';

import { isServer } from '../utils/isServer';

export type TransporterType<T> = {
    id: string;
    _token: Pxth<T>;
};

export const createTransporter = <T>(id: string): TransporterType<T> => ({
    id,
    _token: {} as unknown as Pxth<T>,
});

export function useSender<I>({ id }: TransporterType<I>) {
    const router = useRouter();

    const send = useRefCallback((value: I, redirectLink: string) => {
        sessionStorage.setItem(id, JSON.stringify(value));
        router.replace(redirectLink);
    });

    return send;
}

export function useReceiver<I>({ id }: TransporterType<I>) {
    const [received, setReceived] = useState(false);
    const [receivedValue, setReceivedValue] = useState<I | null>(null);

    useEffect(() => {
        if (!isServer()) {
            const item = sessionStorage.getItem(id);

            if (item !== null) {
                setReceivedValue(JSON.parse(item));
                setReceived(true);

                sessionStorage.removeItem(id);
            }
        }
    }, [id]);

    return [received, receivedValue];
}
