import { useCallback, useEffect, useState } from 'react';

import { isServer } from '../utils/isServer';

export const useSessionStorage = <T>(
    key: string,
): { value: T | null; setValue: (value: T) => void; isLoaded: boolean } => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [value, setValue] = useState<T | null>(null);

    const setSessionStorageValue = useCallback(
        (value: T) => {
            setValue(value);

            const stringifiedValue = JSON.stringify(value);

            sessionStorage.setItem(key, stringifiedValue);
        },
        [key],
    );

    useEffect(() => {
        if (!isServer()) {
            const item = sessionStorage.getItem(key)!;

            setValue(JSON.parse(item));

            setIsLoaded(true);
        }
    }, [key]);

    return { value, setValue: setSessionStorageValue, isLoaded };
};
